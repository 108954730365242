import React from "react";
import LazyLoad from "react-lazyload";
import {
  AiFillCheckSquare,
  AiFillStar,
  AiFillTags,
  AiFillTrophy,
} from "react-icons/ai";
import { FaSuitcase } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";

import "./ImgDivCardTwo.css";

function ImgDivCardTwo({ selectedDestination }) {
  return (
    <>
      <LazyLoad height={200} once>
        <div className="row card-white-div">
          <div className="col-sm-12 pt-2 pb-2">
            <div className="row">
              <div className="col-sm-7 col-md-12 col-lg-7">
                <div className="row">
                  <div className="col-sm-12 mb-4 font-center">
                    <img
                      className="img-card-div-img2-d"
                      src="images/logo.png"
                      alt="side-1"
                    ></img>
                  </div>
                </div>
                <div className="row  d-flex justify-content-center font-center">
                  <div className="col-sm-10">
                    <h4 className="img2-div-h4 font-fm">
                      <b>LO QUE DEBES SABER</b>
                    </h4>
                    <p className="font-fm gray">
                      Disney es el viaje soñado para toda la familia, donde los
                      niños son los principales protagonistas y los padres gozan
                      al ver a sus hijos convertir su sueño en realidad. Un
                      paseo inolvidable que se queda en la memoria de todos, por
                      eso para aprovechar cada momento hemos preparado unos
                      cuantos consejos para que ese sueño te salga economico,
                      ahorres y disfrutes en ese maravilloso lugar.
                    </p>
                    <p className="font-fm gray">
                      Vacation coupon ofrece un plan de COMPRA AHORA VIAJA
                      DESPUES, esto te permite acceder al inventario de los
                      mejores hoteles a precios de remate, dándote accesibilidad
                      al mejor precio.
                    </p>
                    <p className="font-fm gray">
                      Cuando tengas tus fechas listas de viaje, llamanos, unos
                      de nuestros asesores de reserva, se encargara de asignarte
                      tus vacaciones en las fechas deseadas. Tambien te dara los
                      mejores consejos para que vivas unas vacaciones
                      fenomenales en familia.
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-3">
                    <p
                      onClick={() => selectedDestination()}
                      className="btn-about-us-select-top font-fm"
                    >
                      Reservar Ahora
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-5 col-md-12 col-lg-5">
                <div className="row">
                  <div className="col-sm-12">
                    <img
                      className="img-card-div-img2-d1"
                      src="images/side-images/14.jpg"
                      alt="side-1"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} once>
        <div className="row card-white-div mb-5">
          <div className="col-sm-12 pt-2 pb-2">
            <div className="row d-flex justify-content-center font-center">
              <div className="col-sm-10 mb-3">
                <h3 className="img2-div-h3 font-fm">
                  <b>ORLANDO, UNA AVENTURA SIN PRECEDENTES</b>
                </h3>
                <p className="font-fm gray">
                  Orlando, ciudad en el centro de Florida, alberga más de una
                  decena de parques temáticos. Su lugar más famoso es Walt
                  Disney World, que se compone de parques, como Magic Kingdom y
                  Epcot, como también de parques acuáticos. En otro destino
                  importante, Universal Orlando, se encuentran Universal Studios
                  e Islands of Adventure, entre los cuales se ubica The
                  Wizarding World of Harry Potter.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-7 col-md-12 col-lg-7">
                <div className="row d-flex justify-content-center font-center">
                  <div className="col-sm-5 mb-3">
                    <div className="font-center card-shadow-s2">
                      <div className="img-top-set-2">
                        <img
                          className="img-card-div-img2-d2"
                          src="images/side-images/anchor.svg"
                          alt="side-1"
                        ></img>
                      </div>
                      <h5 className="img2-div-h4 font-fm">
                        <b>LO QUE DEBES SABER</b>
                      </h5>
                      <p className="font-fm gray">
                        Con más de 6 parques tematicos y cientas de actividades
                        a explorar!
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-5 mb-3">
                    <div className="font-center card-shadow-s2">
                      <div className="img-top-set-2">
                        <img
                          className="img-card-div-img2-d2"
                          src="images/side-images/time_zone.svg"
                          alt="side-1"
                        ></img>
                      </div>
                      <h5 className="img2-div-h4 font-fm">
                        <b>PERSONAJES</b>
                      </h5>
                      <p className="font-fm gray">
                        Vive y sorprendete con los personajes y todas sus
                        aventuras!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-5 col-md-12 col-lg-5">
                <div className="row d-flex justify-content-center font-center">
                  <div className="col-sm-10">
                    <img
                      className="img-card-div-img2-d1"
                      src="images/side-images/13.jpg"
                      alt="side-1"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-sm-5 col-md-12 col-lg-5">
                <div className="row d-flex justify-content-center font-center">
                  <div className="col-sm-10 mb-3">
                    <img
                      className="img-card-div-img2-d1"
                      src="images/side-images/12.jpg"
                      alt="side-1"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="col-sm-7 col-md-12 col-lg-7">
                <div className="row d-flex justify-content-center font-center">
                  <div className="col-sm-5 mb-3">
                    <div className="font-center card-shadow-s2">
                      <div className="img-top-set-2">
                        <img
                          className="img-card-div-img2-d2"
                          src="images/side-images/distance.svg"
                          alt="side-1"
                        ></img>
                      </div>
                      <h5 className="img2-div-h4 font-fm">
                        <b>GASTRONOMIA</b>
                      </h5>
                      <p className="font-fm gray">
                        Descubre la variedad de restaurantes que tenemos en los
                        parques!
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="font-center card-shadow-s2">
                      <div className="img-top-set-2">
                        <img
                          className="img-card-div-img2-d2"
                          src="images/side-images/beach_umbrella.svg"
                          alt="side-1"
                        ></img>
                      </div>
                      <h5 className="img2-div-h4 font-fm">
                        <b>EVENTOS</b>
                      </h5>
                      <p className="font-fm gray">
                        Disfruta de los mejores eventos que tenemos día a día!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} once>
        <div className="row card-white-div mb-5">
          <div className="col-sm-12 pt-2 pb-2">
            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12">
                    <h4 className="img2-1-div-h4 font-fm">
                      <b>
                        Hotels/Resorts & Cruceros Que puedes escoger en el Plan.
                      </b>
                    </h4>
                    <p className="font-fm gray">
                      Todos los cruceros incluyen COMIDAS Y BEBIDAS. Tambien
                      puedes acceder a otros hoteles de preferencia por un bajo
                      costo adicional.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-md-12 col-lg-4 mb-3">
                <div className="hotel-card-1">
                  <img
                    className="img-hotel-card-1"
                    src="images/side-images/11.jpg"
                    alt="side-1"
                  ></img>
                  <div className="text-h-d">
                    <div className="h-s-d">
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                    </div>
                    <div className="h-name-d">
                      <h5 className="font-fm">
                        Doubletree Hilton Miami Convention Center
                      </h5>
                      <p className="font-fm gray">
                        Miami - 3 Días | 2 Noches de alojamiento
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-12 col-lg-4 mb-3">
                <div className="hotel-card-1">
                  <img
                    className="img-hotel-card-1"
                    src="images/side-images/10.jpg"
                    alt="side-1"
                  ></img>
                  <div className="text-h-d">
                    <div className="h-s-d">
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                    </div>
                    <div className="h-name-d">
                      <h5 className="font-fm">Westgate Vacation Village</h5>
                      <p className="font-fm gray">
                        Orlando - 6 Días | 5 Noches de alojamiento
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-12 col-lg-4 mb-3">
                <div className="hotel-card-1">
                  <img
                    className="img-hotel-card-1"
                    src="images/side-images/9.jpg"
                    alt="side-1"
                  ></img>
                  <div className="text-h-d">
                    <div className="h-s-d">
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                    </div>
                    <div className="h-name-d">
                      <h5 className="font-fm">Crucero Grand Clasica</h5>
                      <p className="font-fm gray">
                        Crucero - 3 Días | 2 Noches de Con comidas y bebidas*
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center font-center mt-5">
              <div className="col-sm-11">
                <div className="div-s-b">
                  <h5 className="font-fm gray">
                    <b>Buscas más un plan vacacional específico?</b> Te lo
                    armamos a tu medida!
                  </h5>
                  <p
                    onClick={() => selectedDestination()}
                    className="btn-about-us-select-top btn-width-ch font-fm"
                  >
                    Reservar Ahora
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-sm-6 col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-sm-2"></div>
                  <div className="col-sm-9">
                    <div className="blue-d-s">
                      <h5 className="font-fm font-center">
                        ESTO ES LO QUE INCLUYE:
                      </h5>
                    </div>
                    <p className="font-fm gray mt-4">
                      <AiFillCheckSquare size={24} color="#00e4ff" /> 10 días /
                      9 noches de alojamiento en uno de nuestros Hotel/Resorts
                      en
                      <b>Miami, Orlando y un Crucero</b> por persona
                    </p>
                    <p className="font-fm gray mt-4">
                      <AiFillCheckSquare size={24} color="#00e4ff" /> Ticket
                      para los parques en Orlando
                    </p>
                    <p className="font-fm gray mt-4">
                      <AiFillCheckSquare size={24} color="#00e4ff" /> 12 Meses
                      para usar tus vacaciones*
                    </p>
                    <p className="font-fm gray mt-4">
                      <AiFillCheckSquare size={24} color="#00e4ff" /> Transporte
                      a los parques en Orlando
                    </p>

                    <p className="font-fm gray mt-4">
                      <AiFillCheckSquare size={24} color="#00e4ff" /> Comidas y
                      bebidas en en el crucero
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-sm-2"></div>
                  <div className="col-sm-9">
                    <div className="red-d-s">
                      <h5 className="font-fm font-center">
                        ¿POR QUÉ NO PUEDES ESPERAR?
                      </h5>
                    </div>
                    <p className="font-fm gray mt-4 font-center">
                      <b>Porque no encontrarás un precio más bajo!</b> Tenemos
                      un gran número de habitaciones disponibles, como parte de
                      la reapertura turística en la Florida, que ya está en
                      marcha. Podrás comprarlas hoy, y utilizarlas en los
                      próximos 12 meses{" "}
                      <b>
                        La oferta es limitada y/o hasta agotar existencias.da
                      </b>
                    </p>
                  </div>
                  {/* <h4 className="font-fm mt-4 font-red-light font-center">
                  Oferta termina en:
                </h4> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} once>
        <div className="row card-blue-div mb-5">
          <div className="col-sm-12 pt-2 pb-2">
            <div className="row">
              <div className="col-sm-4 font-center mb-4">
                <AiFillTags size={50} color="white" />
                <h4 className="font-fm white font-center">
                  Precios sin competencia
                </h4>
                <p className="font-fm white mt-4 font-center">
                  Con más de +5000 proveedores alrededor del mundo y aliados con
                  las mejores líneas de crucero.
                </p>
              </div>
              <div className="col-sm-4 font-center mb-4">
                <AiFillTrophy size={50} color="white" />
                <h4 className="font-fm white font-center">
                  Premiados por el mejor servicio
                </h4>
                <p className="font-fm white mt-4 font-center">
                  Despreocupate, contamos con agentes de viaje calificados para
                  atenderle las 24 horas, los 7 días de la semana.
                </p>
              </div>
              <div className="col-sm-4 font-center">
                <FaSuitcase size={50} color="white" />
                <h4 className="font-fm white font-center">
                  Precios sin competencia
                </h4>
                <p className="font-fm white mt-4 font-center">
                  Con más de +5000 proveedores alrededor del mundo y aliados con
                  las mejores líneas de crucero.
                </p>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} once>
        <div className="row card-white-div">
          <div className="col-sm-12 pt-2 pb-2">
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                    <img
                      className="img1-l-card-2"
                      src="images/side-images/m.png"
                      alt="side-1"
                    ></img>
                    <h4 className="font-fm gray font-center img1-l-card-2-t">
                      <b>QUIENES SOMOS?</b>
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <p className="font-fm gray mt-4">
                      Vacation Coupon es el sitio web de viajes más grande de
                      Estados Unidos para cruceros, hoteles y complejos
                      turísticos con descuentos inigualables. Desde su fundación
                      en el año 2006, ha logrado que más 2 millones clientes
                      tengan unas vacaciones de ensueño con un precio más bajo
                      de lo normal.
                    </p>
                    <p className="font-fm gray mt-4">
                      Vacations Coupon es una agencia de viajes consolidada y
                      miembro acreditado de Airlines Reporting Corporation
                      (ARC), International Airlines Travel Agent Network (IATAN)
                      y Cruise Line International Association (CLIA). Ha logrado
                      el más alto nivel de reconocimiento posible de todas las
                      principales líneas de cruceros del mundo por ventas
                      excepcionales y un servicio superior.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12 mt-4">
                    <img
                      className="img2-l-card-2"
                      src="images/side-images/p.png"
                      alt="side-1"
                    ></img>
                    <h4 className="font-fm gray font-center img1-l-card-2-t">
                      <b>SOMOS PLANIFICADORES DE VIAJE</b>
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <p className="font-fm gray mt-4">
                      Contamos con expertos en planificaciones en viajes al
                      mundo, los cuales te ayudaran a encontrar la información
                      que necesitas y brindarte soporte para planificar las
                      vacaciones de tu familia. Ya sea que estés planificando un
                      escape de fin de semana, un crucero relajante o días y
                      noches de diversión en los parques temáticos de Disney o
                      Universal en Orlando, relájate y deja que uno de nuestros
                      expertos lo haga por ti.
                    </p>
                    <p className="font-fm gray mt-4">
                      Con más de 25 años de experiencia, hemos vacacionado a más
                      de 10 Mil familias en toda Latino américa, brindando
                      numerosas experiencias de vacacionar con las mejores
                      ofertas.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center font-center">
              <div className="col-sm-8 mt-5">
                <h4 className="font-fm gray">
                  <b>Quieres que te llamemos?</b>
                </h4>
                <p className="font-fm gray">
                  programa una llamada con uno de nuestros agentes, y te
                  llamamos el día y en el horario que nos indiques
                </p>
                <p
                  onClick={() => selectedDestination()}
                  className="btn-about-us-select-top btn-width-ch font-fm"
                >
                  Reservar Ahora
                </p>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} once>
        <div className="row card-white-div mt-5">
          <div className="col-sm-12 pt-2 pb-2">
            <div className="row d-flex justify-content-center font-center">
              <div className="col-sm-10 mt-5">
                <h4 className="font-fm gray">
                  <b>PREGUNTAS FRECUENTES</b>
                </h4>
                <p className="font-fm gray">
                  Sabemos que tienes preguntas, es por esto, que hemos
                  recopilado una serie de preguntas frecuentes de nuestros
                  clientes para resolver tus inquietudes. Si necesitas saber la
                  respuesta, cliquea en la pregunta y una ventana emergente te
                  indicara la respuesta.
                </p>
              </div>
            </div>
            <div className="row ml-1 mr-1 accordion-faq-div">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-6">
                    <Accordion defaultActiveKey="10">
                      <Accordion.Item eventKey="10">
                        <Accordion.Header>
                          <h5 className="home-faq-text">
                            ¿Incluye tickets aereos?
                          </h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="home-faq-sub">
                                tickets aéreos no están Incluidos. Podemos
                                cotizar sus tickets aéreos si tiene fecha exacta
                                de viaje. Los tickets aéreos están sujetos a
                                cambios por la aerolínea de escogencia y son un
                                servicio independiente a nuestros planes
                                vacacionales
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="11">
                        <Accordion.Header>
                          <h5 className="home-faq-text">
                            ¿Cómo puedo pagar el plan vacacional?
                          </h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="home-faq-sub">
                                Puedes realizar tu pago con nuestros agentes en
                                línea, con tarjetas tarjeta VISA MASTERCARD,
                                AMERICAN EXPRESS o DINERS. Si deseas realizar un
                                pago tu mismo(a), ellos te enviarán un link a tu
                                email o whatsapp donde podrás completar la
                                información de pago con tu tarjeta crediticia.
                                De acuerdo a las opciones de pago elegidas por
                                el comercio, podrás pagar a través de Ebanx.
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="12">
                        <Accordion.Header>
                          <h5 className="home-faq-text">
                            ¿Puedo pagar mi plan en cuotas?
                          </h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="home-faq-sub">
                                Puedes pagar hasta 12 cuotas sin intereses si
                                estas en los países Argentina, Mexico, Colombia
                                o Peru. Otros países puede variar dependiendo de
                                su entidad crediticia.
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="13">
                        <Accordion.Header>
                          <h5 className="home-faq-text">
                            ¿Necesito VISA para viajar?
                          </h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="home-faq-sub">
                                Solo Ecuador necesita visa para entrar a Mexico.
                                El resto de paises estan exceptos de presentar
                                una Visa en Mexico y Punta Cana. Si su destino
                                es Estados Unidos, Usted necesitara una Visa de
                                turista para ingresar a este. Consulte con la
                                embajada más cercana en su pais.
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  <div className="col-sm-6">
                    <Accordion defaultActiveKey="10">
                      <Accordion.Item eventKey="10">
                        <Accordion.Header>
                          <h5 className="home-faq-text">
                            ¿Cuándo debo realizar el pago?
                          </h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="home-faq-sub">
                                El paquete se debe pagar de inmediato para
                                separar el cupo, todos nuestros planes tienen
                                precios promocionales y debido a los cupos
                                limitados y los descuentos ofrecidos, se debe
                                que pagar lo más adelantado posible para poder
                                ser acreedor a las tarifas en oferta.
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="11">
                        <Accordion.Header>
                          <h5 className="home-faq-text">
                            ¿Que documentos/garantias recibo después de realizar
                            el pago?
                          </h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="home-faq-sub">
                                Recibirás un un recibo de procesamiento y un
                                contrato que deberás firmar y retornarnos con
                                los documentos de identificación indicados en el
                                mismo. También recibirás un itinerario al
                                momento de indicarnos las fechas en las cuales
                                tiene pensado viajar.
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="12">
                        <Accordion.Header>
                          <h5 className="home-faq-text">
                            ¿Puedo anular o modificar una reserva del viaje?
                            ¿Qué gastos puede generar una anulación o
                            modificación del viaje?
                          </h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="home-faq-sub">
                                Sí, sí puedes. Pero ten en cuenta que dicha
                                anulación puede conllevar unos gastos de
                                cancelación. Al realizar la reserva, se informa
                                de la política de cancelación, o bien ésta
                                figura en las condiciones generales.Estos gastos
                                de cancelación son variables según el destino,
                                el proveedor, la tarifa reservada y las fechas
                                de antelación a la anulación. Para anular o
                                modificar una reserva, a través del apartado
                                CONTACTO envía un email indicando el localizador
                                de la reserva y la modificación que deseas
                                realizar.También se puede enviar un mensaje a
                                través del apartado MIS RESERVAS, ver detalle de
                                la reserva en cuestión y opción enviar un e-mail
                                al booking.
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} once>
        <div className="row card-white-div mt-5">
          <div className="col-sm-12 pt-2 pb-2">
            <div className="row">
              <div className="col-sm-7 col-md-12 col-lg-7">
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-10 mt-3">
                    <h4 className="img2-div-h4 font-fm">
                      DEJE SU VIAJE EN MANOS DE UN EXPERTO
                    </h4>
                    <h5 className="img2-div-h3 font-fm">
                      Somos el mayorista de turismo por excelencia!
                    </h5>
                    <p className="font-fm gray">
                      Sabemos, comprendemos y entendemos tus necesidades a la
                      hora de viajar.
                    </p>
                    <p className="font-fm gray">
                      Al comprar con nosotros estás comprando con expertos en
                      turismo que están ahí para ti.
                    </p>
                    <p className="font-fm gray">
                      Nuestra empresa cuenta con más de 250 agentes,
                      especialmente entrenados para atender tus necesidades a la
                      hora de vacacionar, responder a tus preguntas y asistirte
                      durante tu viaje.
                    </p>
                    <p className="font-fm gray">
                      Contamos con nuestra Licencia de viajes y turismo en el
                      Estado de la Florida y una garantía de depósito de más de
                      un millón de dólares que respaldan nuestras operaciones y
                      aseguran tus vacaciones.
                    </p>
                    <p className="font-fm gray">
                      <b>Vacaciones al mundo</b> te ofrece un servicio
                      personalizado durante a través de nuestros agentes
                      internacionales, para hacer que tu experiencia con
                      nosotros la mejor que hayas vivido.
                    </p>
                    <p className="font-fm gray">¿Tienes preguntas?</p>
                    <p className="font-fm gray">
                      No sabes qué plan seleccionar?
                    </p>
                    <p className="font-fm gray">
                      ¿Quieres información acerca de alguno de nuestros
                      destinos?
                    </p>
                    <p className="font-fm gray">
                      <b>
                        ¡Llámanos, nuestros agentes esperan por tu llamada para
                        asistirte!
                      </b>
                    </p>
                    <p className="font-fm gray">
                      Qué nos hace diferente al resto de las agencias de viajes
                      en linea? Nos especializamos en una gama de planes
                      vacacionales donde los principales destinos son: Miami,
                      Orlando, Las Vegas, Bahamas, Cancún y Punta Cana, a los
                      mejores precios, sin competencia. Comprobado.
                    </p>
                    <p className="font-fm gray">
                      Al hacer tu registro en línea, asignamos un agente
                      internacional de viajes quien te asesorará, de la mejor
                      manera, para que hagas la selección y cotización de tu
                      viaje apropiadamente.
                    </p>
                    <p className="font-fm gray">
                      En nuestra filosofía Tú eres parte esencial de nuestro
                      negocio.Pago único y final, sin cargos escondidos.
                      Confianza y un servicio de excelencia. Planes vacacionales
                      que se ajustan a tu bolsillo.
                    </p>
                    <p className="font-fm gray">
                      <b>
                        Utilizamos conexiones seguras para proteger toda tu
                        información.
                      </b>
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-3">
                    <p
                      onClick={() => selectedDestination()}
                      className="btn-about-us-select-top font-fm"
                    >
                      Reservar Ahora
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-5 col-md-12 col-lg-5">
                <div className="row">
                  <div className="col-sm-12">
                    <img
                      className="img-card-div-img2-d1"
                      src="images/side-images/disney_w_the_fam.jpg"
                      alt="side-1"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
    </>
  );
}

export default ImgDivCardTwo;
