import React, { lazy } from "react";
import { addDays } from "date-fns";
import { toast } from "react-toastify";

import HomePage from "./HomePage";
import { API_URL } from "../Data/constants.js";
import { EventFunction } from "../Tracking";

import "./RootPage.css";

const DateRangeDiv = lazy(() => import("./Steps/DateRangeDiv/DateRangeDiv"));
const DoHaveDate = lazy(() => import("./Steps/DoHaveDate/DoHaveDate"));
const NumOfTourist = lazy(() => import("./Steps/NumOfTourist/NumOfTourist"));
const SubmitDetails = lazy(() => import("./Steps/SubmitDetails/SubmitDetails"));
const WhatActivitiesTab = lazy(() =>
  import("./Steps/WhatActivitiesTab/WhatActivitiesTab")
);
const WhatBugetTab = lazy(() => import("./Steps/WhatBugetTab/WhatBugetTab"));
const WhatCityLeave = lazy(() => import("./Steps/WhatCityLeave/WhatCityLeave"));
const WhenDoYou = lazy(() => import("./Steps/WhenDoYouPage/WhenDoYou"));
const WhichCitiesVisit = lazy(() =>
  import("./Steps/WhichCitiesVisit/WhichCitiesVisit")
);
const BookingPage = lazy(() => import("./BookingPage/BookingPage"));
const Payment = lazy(() => import("./payment/Payment"));
const ThankYouPage = lazy(() => import("./Steps/ThankYouPage/ThankYouPage"));

class RootPage extends React.Component {
  constructor(props) {
    super(props);

    // Bind the this context to the changeCurrentPage function
    this.changeCurrentPage = this.changeCurrentPage.bind(this);
    this.changeCountryCode = this.changeCountryCode.bind(this);
    this.changeWhenDoYouGo = this.changeWhenDoYouGo.bind(this);
    this.changeAdultNumber = this.changeAdultNumber.bind(this);
    this.changeKidNumber = this.changeKidNumber.bind(this);
    this.changeWhereDoYouGo = this.changeWhereDoYouGo.bind(this);
    this.changeWhenDoYouGoSelect = this.changeWhenDoYouGoSelect.bind(this);
    this.selectActivity = this.selectActivity.bind(this);
    this.selectBuget = this.selectBuget.bind(this);
    this.changeWhereLikeGo = this.changeWhereLikeGo.bind(this);
    this.changeWhereLikeLeave = this.changeWhereLikeLeave.bind(this);
    this.changeContactDetails = this.changeContactDetails.bind(this);
    this.submitDetails = this.submitDetails.bind(this);
    this.changeTourStartEndDates = this.changeTourStartEndDates.bind(this);
    this.changeKidsAge = this.changeKidsAge.bind(this);
    this.changePaymentValues = this.changePaymentValues.bind(this);
    this.changeInvoiceValues = this.changeInvoiceValues.bind(this);

    // Set some state
    this.state = {
      currentPage: "HOME",
      whereLikeGo: null,
      whenDoYouGo: 0,
      selectedAdultNum: { value: "852", text: "1 Adulto", name: 1 },
      selectedKidNum: { value: "857", text: "Sin Niños", name: 0 },
      whereDoYouGo: null,
      selectedActivities: [0, 0, 0, 0, 0, 0],
      selectedBuget: [0, 0, 0, 0, 0, 0],
      whereLikeLeave: null,
      name: "",
      surname: "",
      email: "",
      countryAndCity: "",
      phone_number: "",
      whenDoYouGoSelect: null,
      tourStartDate: addDays(new Date(), 2),
      tourEndDate: addDays(new Date(), 2),
      count: 0,
      ipLocation: null,
      countryCode: null,
      inputkidAgeList: [12, 12, 12, 12],
      customerDetails: null,
      paymentValues: null,
      invoiceDetails: null,
    };
  }

  getLocationFromQuery() {
    const urlParams = new URLSearchParams(window.location.search);
    let source = urlParams.get("source");

    if (source) {
      return source;
    } else {
      return "DIRECT URL";
    }
  }

  componentDidMount() {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    let url = "https://api.ipify.org?format=json";

    fetch(url, requestOptions)
      .then((response) => {
        const data = response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;

          return Promise.reject(error);
        }

        const waitToGetResponse = async () => {
          const a = await data;

          this.setState({ ipLocation: a.ip });
        };

        waitToGetResponse();
      })
      .catch((error) => {
        //console.error("There was an error!", error);
      });
  }

  changeCurrentPage(newPage) {
    this.setState({
      currentPage: newPage,
    });
  }

  changeKidsAge(newPage, ageListArray) {
    this.setState({
      currentPage: newPage,
      inputkidAgeList: ageListArray,
    });
  }

  changeCountryCode(code) {
    this.setState({
      countryCode: code,
    });
  }

  changeWhenDoYouGo(goingDate) {
    this.setState({
      currentPage: "STEP_3",
      whenDoYouGo: goingDate,
    });

    switch (goingDate) {
      case 1:
        EventFunction("ROUGH DATE", "Click", "Ene - Mar");
        break;
      case 2:
        EventFunction("ROUGH DATE", "Click", "Abr - Jun");
        break;
      case 3:
        EventFunction("ROUGH DATE", "Click", "Jul - Sept");
        break;
      case 4:
        EventFunction("ROUGH DATE", "Click", "Oct - Dic");
        break;
      case 5:
        EventFunction("ROUGH DATE", "Click", "No estoy seguro");
        break;
      default:
        EventFunction("ROUGH DATE", "Click", "No estoy seguro");
    }
  }

  changeTourStartEndDates(tourStartDate, tourEndDate) {
    this.setState({
      tourStartDate: tourStartDate,
      tourEndDate: tourEndDate,
      count: this.state.count + 1,
    });

    //let startEndDate = tourStartDate + " - " + tourEndDate;
    //EventFunction("DATE RANGE", "Click", startEndDate);
  }

  changePaymentValues(values) {
    this.setState({
      paymentValues: values,
      currentPage: "PAYMENT",
      customerDetails: {
        firstName: this.state.name,
        lastName: this.state.surname,
        email: this.state.email,
        phoneNumber: this.state.phone_number,
        adultsNumber: values.adultNumber,
        kidsNumber: values.kidNumber,
        infantNumber: values.infantNumber,
      },
    });

    //console.log(values);
  }

  changeInvoiceValues(values) {
    this.setState({
      invoiceDetails: values,
      currentPage: "FINISHED",
    });
  }

  changeWhereLikeGo(city) {
    this.setState({
      currentPage: "STEP_1",
      whereLikeGo: city,
    });
  }

  changeWhereLikeLeave(city) {
    this.setState({
      currentPage: "STEP_6",
      whereLikeLeave: city,
    });
  }

  changeContactDetails(name, surname, email, countryAndCity, phone_number) {
    this.setState({
      name: name,
      surname: surname,
      email: email,
      countryAndCity: countryAndCity,
      phone_number: phone_number,
    });
  }

  selectActivity(activityNum) {
    let newSet = this.state.selectedActivities;
    if (newSet[activityNum] === 0) {
      newSet[activityNum] = 1;
    } else if (newSet[activityNum] === 1) {
      newSet[activityNum] = 0;
    }
    this.setState({
      selectedActivities: newSet,
    });

    let activityList = ["Walt Disney World", "Universal Studios"];

    let sub_activity_tmp = "";
    for (let x = 0; x < activityList.length; x++) {
      if (newSet[x] === 1) {
        sub_activity_tmp = activityList[x] + ", " + sub_activity_tmp;
      }
    }

    EventFunction("ACTIVITIES", "Click", sub_activity_tmp);
  }

  selectBuget(bugetNum) {
    let newSet = this.state.selectedBuget;
    if (newSet[bugetNum] === 0) {
      newSet[bugetNum] = 1;
    } else if (newSet[bugetNum] === 1) {
      newSet[bugetNum] = 0;
    }
    this.setState({
      selectedBuget: newSet,
    });

    let budgetList = ["Presupuesto", "Rango medio", "Lujo", "Ultra"];

    let sub_budget_tmp = "";
    for (let x = 0; x < budgetList.length; x++) {
      if (newSet[x] === 1) {
        sub_budget_tmp = budgetList[x] + ", " + sub_budget_tmp;
      }
    }

    EventFunction("BUDGET", "Click", sub_budget_tmp);
  }

  changeWhereDoYouGo(whereGoing) {
    this.setState({
      currentPage: "STEP_5",
      whereDoYouGo: whereGoing,
    });

    if (whereGoing === 0) {
      EventFunction("TRIP TYPE", "Click", "Hotel");
    } else {
      EventFunction("TRIP TYPE", "Click", "Hotel + Vuelos");
    }
  }

  changeWhenDoYouGoSelect(whenDoYouGoSelect) {
    this.setState({
      currentPage: "STEP_2",
      whenDoYouGoSelect: whenDoYouGoSelect,
    });

    if (whenDoYouGoSelect === 0) {
      EventFunction("DO HAVE DATE", "Click", "Sí tengo");
    } else {
      EventFunction("DO HAVE DATE", "Click", "No, no tengo");
    }
  }

  changeAdultNumber(adultObj) {
    this.setState({
      selectedAdultNum: adultObj,
    });
  }

  changeKidNumber(kidObj) {
    this.setState({
      selectedKidNum: kidObj,
    });
  }

  successSubmission() {
    this.setState({
      currentPage: "BOOKING",
      customerDetails: {
        firstName: this.state.name,
        lastName: this.state.surname,
        email: this.state.email,
        phoneNumber: this.state.phone_number,
        adultsNumber: this.state.selectedAdultNum.name,
        kidsNumber: this.state.selectedKidNum.name,
        infantNumber: 0,
      },
    });
  }

  submitButtonLoading() {
    document.getElementsByClassName("loading_submit_btn")[0].style.display =
      "block";
    document.getElementsByClassName("submit_btn_txt")[0].style.display = "none";
    document.getElementsByClassName("submit-btn-style")[0].style.pointerEvents =
      "none";
    document.getElementsByClassName(
      "submit-btn-style"
    )[0].style.backgroundColor = "#36cba8";

    document.getElementsByClassName("input-box-style")[0].disabled = true;
    document.getElementsByClassName("input-box-style")[1].disabled = true;
    document.getElementsByClassName("input-box-style")[2].disabled = true;
    document.getElementsByClassName("react-tel-input")[0].style.pointerEvents =
      "none";
    document.getElementsByClassName(
      "input-box-style-city"
    )[0].style.pointerEvents = "none";
  }

  btnSetInitState() {
    document.getElementById("submit-btn-final").style.display = "block";
    document.getElementsByClassName("loading_submit_btn")[0].style.display =
      "none";
    document.getElementsByClassName("submit_btn_txt")[0].style.display =
      "block";
    document.getElementsByClassName("submit-btn-style")[0].style.pointerEvents =
      "visible";
    document.getElementsByClassName(
      "submit-btn-style"
    )[0].style.backgroundColor = "#185cf5";
    document.getElementsByClassName("input-box-style")[0].disabled = false;
    document.getElementsByClassName("input-box-style")[1].disabled = false;
    document.getElementsByClassName("input-box-style")[2].disabled = false;
    document.getElementsByClassName(
      "input-box-style-city"
    )[0].style.pointerEvents = "auto";
    document.getElementsByClassName("react-tel-input")[0].style.pointerEvents =
      "auto";
  }
  987;

  failSubmissionMessage(error_msg_set) {
    toast.error("Se produjo un error. Vuelve a intentarlo más tarde.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    let set_error_msg = "ERROR_" + error_msg_set;

    EventFunction("SUBMISSION", "Result", "Fail Submissions");
    EventFunction("ERROR", "Message", set_error_msg);
  }

  submitDetails() {
    this.submitButtonLoading();

    EventFunction("SUBMISSION", "Click", "Submission Button click");

    let sub_whereLikeGo = "";
    let sub_whenGo = "";
    let sub_adult_num = "";
    let sub_kid_num = "";

    let tripTypeArray = ["Una ciudad", "Varias ciudades"];
    let tripTypeArrayID = [1194, 1195];
    let sub_tripType = "";

    let sub_whereLikeLeave = "";

    let activityList = ["Walt Disney World", "Universal Studios"];
    let budgetList = ["Presupuesto", "Rango medio", "Lujo", "Ultra"];

    let sub_activity = "";
    let sub_budget = "";
    let likeGoDestinationCode = "";
    let likeGoDestinationName = "";

    //select where like go
    if (this.state.whereLikeGo === "NOT SPECIFY") {
      sub_whereLikeGo = "NOT SPECIFY";
    } else {
      let selectVal = this.state.whereLikeGo;

      for (let i = 0; i < selectVal.length; i++) {
        sub_whereLikeGo = sub_whereLikeGo.concat(selectVal[i].value);
        sub_whereLikeGo = sub_whereLikeGo.concat(" | ");
      }

      likeGoDestinationCode = selectVal[0].code;
      likeGoDestinationName = selectVal[0].value.split(", ")[0];
    }

    //select when like go
    let travelDateID = 1196;
    let startDateSet = "";
    let endDateSet = "";
    let dateNotSure = "";

    let monthObj = [];
    let startDateNormalFMT = "";
    let endDateNormalFMT = "";

    monthObj["Jan"] = "01";
    monthObj["Feb"] = "02";
    monthObj["Mar"] = "03";
    monthObj["Apr"] = "04";
    monthObj["May"] = "05";
    monthObj["Jun"] = "06";
    monthObj["Jul"] = "07";
    monthObj["Aug"] = "08";
    monthObj["Sep"] = "09";
    monthObj["Oct"] = "10";
    monthObj["Nov"] = "11";
    monthObj["Dec"] = "12";

    if (this.state.whenDoYouGoSelect === 0) {
      let startDate = String(this.state.tourStartDate);
      startDate = startDate.split(" ");

      let endDate = String(this.state.tourEndDate);
      endDate = endDate.split(" ");

      sub_whenGo = startDate[1] + "/" + startDate[2] + "/" + startDate[3];
      startDateSet =
        monthObj[startDate[1]] + "/" + startDate[2] + "/" + startDate[3];
      startDateNormalFMT =
        startDate[2] + "/" + monthObj[startDate[1]] + "/" + startDate[3];

      sub_whenGo =
        sub_whenGo + " - " + endDate[1] + "/" + endDate[2] + "/" + endDate[3];
      endDateSet = monthObj[endDate[1]] + "/" + endDate[2] + "/" + endDate[3];
      endDateNormalFMT =
        endDate[2] + "/" + monthObj[endDate[1]] + "/" + endDate[3];
    } else {
      travelDateID = 1197;
      switch (this.state.whenDoYouGo) {
        case 1:
          sub_whenGo = "Ene - Mar";
          dateNotSure = sub_whenGo;
          break;
        case 2:
          sub_whenGo = "Abr - Jun";
          dateNotSure = sub_whenGo;
          break;
        case 3:
          sub_whenGo = "Jul - Sept";
          dateNotSure = sub_whenGo;
          break;
        case 4:
          sub_whenGo = "Oct - Dic";
          dateNotSure = sub_whenGo;
          break;
        default:
          sub_whenGo = "No estoy seguro";
          dateNotSure = sub_whenGo;
      }
    }

    sub_adult_num = this.state.selectedAdultNum.value;
    sub_kid_num = this.state.selectedKidNum.value;

    sub_tripType = tripTypeArray[this.state.whereDoYouGo];

    let likeLeaveDestinationCode = "";

    if (this.state.whereLikeLeave === "NOT SPECIFY") {
      sub_whereLikeLeave = "NOT SPECIFY";
    } else {
      sub_whereLikeLeave = this.state.whereLikeLeave.value;
      likeLeaveDestinationCode = this.state.whereLikeLeave.code;
    }

    let selectedActivities = this.state.selectedActivities;

    for (let x = 0; x < activityList.length; x++) {
      if (selectedActivities[x] === 1) {
        sub_activity = activityList[x] + ", " + sub_activity;
      }
    }

    let selectedBudget = this.state.selectedBuget;

    for (let x = 0; x < selectedBudget.length; x++) {
      if (selectedBudget[x] === 1) {
        sub_budget = budgetList[x] + ", " + sub_budget;
      }
    }

    let sub_message = "";

    sub_message = "A dónde quieres ir? " + sub_whereLikeGo;
    sub_message = sub_message + "\nFechas de viaje? " + sub_whenGo;
    sub_message =
      sub_message + "\nAdultos: " + this.state.selectedAdultNum.name;
    sub_message = sub_message + "\nNiños: " + this.state.selectedKidNum.name;
    sub_message =
      sub_message +
      "\nEdad de los Niños: " +
      this.state.inputkidAgeList.slice(0, this.state.selectedKidNum.name);
    sub_message = sub_message + "\nTipo de viaje? " + sub_tripType;
    sub_message = sub_message + "\n¿Salir de? " + sub_whereLikeLeave;
    sub_message = sub_message + "\nOcupaciones: " + sub_activity;
    sub_message = sub_message + "\nPresupuesto: " + sub_budget;

    let sub_first_name = this.state.name;
    let sub_last_name = this.state.surname;
    let sub_email = this.state.email;
    let sub_city = this.state.countryAndCity.value;
    let sub_phone = this.state.phone_number;
    let sub_ipLocation = this.state.ipLocation;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        first_name: sub_first_name,
        last_name: sub_last_name,
        email: sub_email,
        country_city: sub_city,
        phone_number: sub_phone,
        ip_location: sub_ipLocation,
        answer_set: sub_message,
        like_to_go: sub_whereLikeGo,
        travelDateID: travelDateID,
        start_date: startDateSet,
        end_date: endDateSet,
        date_not_sure: dateNotSure,
        adults_number: sub_adult_num,
        kids_number: sub_kid_num,
        kids_age: String(
          this.state.inputkidAgeList.slice(0, this.state.selectedKidNum.name)
        ),
        travel_type: tripTypeArrayID[this.state.whereDoYouGo],
        leave_location: sub_whereLikeLeave,
        activity_list: sub_activity,
        budget_list: sub_budget,
        source_title: "Vacation Coupon - Trip Planner",
        source_id: "34",
        source_url: "https://planificador.vacationcoupon.com/",
        likeGoDestinationCode: likeGoDestinationCode,
        likeGoDestinationName: likeGoDestinationName,
        campaign: this.getLocationFromQuery(),
      }),
    };

    let url = API_URL + "planner_leads/create.php/";

    fetch(url, requestOptions)
      .then((response) => {
        const data = response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;

          return Promise.reject(error);
        }

        const waitToGetResponse = async () => {
          const a = await data;

          if (!(a["server_error"] || a["user_error"])) {
            //if success
            this.successSubmission();

            window.gtag("event", "conversion", {
              send_to: "AW-612036340/JFwdCJza2f4CEPTd66MC",
            });

            EventFunction("SUBMISSION", "Result", "Success Submissions");
          } else {
            this.btnSetInitState();

            let error_msg_set = "";
            if (typeof a["server_error"] === "undefined") {
              if (typeof a["user_error"] === "undefined") {
              } else {
                error_msg_set = a["user_error"];
              }
            } else {
              error_msg_set = a["server_error"];

              if (typeof a["user_error"] === "undefined") {
              } else {
                error_msg_set = a["user_error"] + " | " + a["server_error"];
              }
            }

            this.failSubmissionMessage(error_msg_set);
          }
        };

        waitToGetResponse();
      })
      .catch((error) => {
        this.btnSetInitState();
        this.failSubmissionMessage("Server error");
        // console.error('There was an error!', error);
      });
  }

  render() {
    const renderPage = () => {
      switch (this.state.currentPage) {
        case "STEP_1":
          return (
            <DoHaveDate
              changeCurrentPage={this.changeCurrentPage}
              changeWhenDoYouGoSelect={this.changeWhenDoYouGoSelect}
              whenDoYouGoSelect={this.state.whenDoYouGoSelect}
            />
          );
        case "STEP_2":
          if (this.state.whenDoYouGoSelect === 0) {
            return (
              <DateRangeDiv
                changeCurrentPage={this.changeCurrentPage}
                changeTourStartEndDates={this.changeTourStartEndDates}
                tourStartDate={this.state.tourStartDate}
                tourEndDate={this.state.tourEndDate}
                count={this.state.count}
              />
            );
          } else if (this.state.whenDoYouGoSelect === 1) {
            return (
              <WhenDoYou
                changeCurrentPage={this.changeCurrentPage}
                changeWhenDoYouGo={this.changeWhenDoYouGo}
                whenDoYouGo={this.state.whenDoYouGo}
              />
            );
          } else {
            return (
              <WhenDoYou
                changeCurrentPage={this.changeCurrentPage}
                changeWhenDoYouGo={this.changeWhenDoYouGo}
                whenDoYouGo={this.state.whenDoYouGo}
              />
            );
          }
        case "STEP_3":
          return (
            <NumOfTourist
              changeKidsAgePage={this.changeKidsAge}
              changeAdultNumber={this.changeAdultNumber}
              adultNumber={this.state.selectedAdultNum}
              changeKidNumber={this.changeKidNumber}
              kidNumber={this.state.selectedKidNum}
              inputkidAgeList={this.state.inputkidAgeList}
            />
          );
        case "STEP_4":
          return (
            <WhichCitiesVisit
              changeCurrentPage={this.changeCurrentPage}
              whereDoYouGo={this.state.whereDoYouGo}
              changeWhereDoYouGo={this.changeWhereDoYouGo}
              ipLocation={this.state.ipLocation}
              changeCountryCode={this.changeCountryCode}
            />
          );
        case "STEP_5":
          return (
            <WhatCityLeave
              changeCurrentPage={this.changeCurrentPage}
              whereLikeLeave={this.state.whereLikeLeave}
              changeWhereLikeLeave={this.changeWhereLikeLeave}
            />
          );
        case "STEP_6":
          return (
            <WhatActivitiesTab
              changeCurrentPage={this.changeCurrentPage}
              selectActivity={this.selectActivity}
              activitySet={this.state.selectedActivities}
            />
          );
        case "STEP_7":
          return (
            <WhatBugetTab
              changeCurrentPage={this.changeCurrentPage}
              selectBuget={this.selectBuget}
              bugetSet={this.state.selectedBuget}
            />
          );
        case "STEP_8":
          return (
            <SubmitDetails
              changeCurrentPage={this.changeCurrentPage}
              changeContactDetails={this.changeContactDetails}
              returnContactObj={this.returnContactObj}
              returnName={this.state.name}
              returnSurName={this.state.surname}
              returnEmail={this.state.email}
              returnCountryCity={this.state.countryAndCity}
              returnPhone={this.state.phone_number}
              submitDetails={this.submitDetails}
              countryCode={this.state.countryCode}
            />
          );
        case "BOOKING":
          return (
            <BookingPage
              changePaymentValues={this.changePaymentValues}
              customerDetails={this.state.customerDetails}
            />
          );
        case "PAYMENT":
          return (
            <Payment
              paymentValues={this.state.paymentValues}
              customerDetails={this.state.customerDetails}
              changeInvoiceValues={this.changeInvoiceValues}
              paymentToBookingBack={this.changeCurrentPage}
            />
          );
        case "FINISHED":
          return (
            <ThankYouPage
              invoiceDetails={this.state.invoiceDetails}
              customerDetails={this.state.customerDetails}
            />
          );
        default:
          return (
            <HomePage
              changeCurrentPage={this.changeCurrentPage}
              whereLikeGo={this.state.whereLikeGo}
              changeWhereLikeGo={this.changeWhereLikeGo}
            />
          );
      }
    };

    return (
      <div>
        <div id="nav_bar_height" className="container">
          <div className="row">
            <div className="col-sm-12 d-flex justify-content-start">
              <img
                className="logo-styles"
                src="images/logo.png"
                alt="logo"
              ></img>
            </div>
          </div>
        </div>
        {renderPage()}
      </div>
    );
  }
}

export default RootPage;
