import React, { Suspense, useEffect, useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import LazyLoad from "react-lazyload";

import LoadingScreen from "./RootPage/LoadingScreen/LoadingScreen";
import RootPage from "./RootPage/RootPage";
import { TRACKING_ID } from "./Data/constants";
import { PageView, initGA } from "./Tracking/index";
import Page404 from "./RootPage/page404/Page404";
import PartnersSection from "./components/partners-section/PartnersSection";
import Footer from "./components/Footer/Footer";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    initGA(TRACKING_ID);
    PageView();
  });

  return (
    <ScrollToTop>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
          <Route path="/" exact render={(props) => <RootPage />} />
          <Route path="*" exact={true} component={Page404} />
        </Switch>
      </Suspense>
      <LazyLoad height={200} once>
        <PartnersSection />
      </LazyLoad>
      <LazyLoad height={200} once>
        <Footer />
      </LazyLoad>
    </ScrollToTop>
  );
}

export default App;
