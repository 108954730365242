import React, { Component } from "react";
import { AiFillStar } from "react-icons/ai";

import { TRACKING_ID } from "../../Data/constants";
import { EventFunction, initGA } from "../../Tracking/index";
import ImgDivCard from "./ImgDivCard/ImgDivCard";
import ImgDivCardTwo from "./ImgDivCardTwo/ImgDivCardTwo";
import AboutCardSection from "./AboutCardSection/AboutCardSection";

import "./homePage.css";

class HomePage extends Component {
  state = {};

  selectedDestination() {
    this.props.changeWhereLikeGo([
      {
        value: "Orlando (Florida), US",
        label: "Orlando (Florida), US",
        code: "MCO",
      },
      {
        value: "Miami (Florida), US",
        label: "Miami (Florida), US",
        code: "MIA",
      },
    ]);

    initGA(TRACKING_ID);
    EventFunction("HOME PAGE", "Click", "Reservar Ahora");
  }

  SmoothVerticalScrolling(eTop, time) {
    var eAmt = eTop / 100;
    var curTime = 0;

    while (curTime <= time) {
      window.setTimeout(this.SVS_B, curTime, eAmt);
      curTime += time / 100;
    }
  }

  SVS_B(eAmt) {
    window.scrollBy(0, eAmt);
  }

  scrollToBottom() {
    let fullBodyHeight =
      document.getElementById("full_body_size").offsetHeight +
      document.getElementById("nav_bar_height").offsetHeight;

    this.SmoothVerticalScrolling(fullBodyHeight, 500);

    initGA(TRACKING_ID);
    EventFunction("HOME PAGE", "Click", "Conoce más");
  }

  scrollToTop() {
    let fullBodyHeight =
      document.getElementById("full_body_size").offsetHeight +
      document.getElementById("about-us-div").offsetHeight;

    this.SmoothVerticalScrolling(-fullBodyHeight, 500);

    initGA(TRACKING_ID);
    EventFunction("HOME PAGE", "Click", "Cotizar ahora");
  }

  render() {
    return (
      <div className="relative">
        <img className="img_1_set" src="images/samolot_6.svg" alt="img" />
        <img className="img_2_set" src="images/samolot_5.svg" alt="img" />
        <div id="full_body_size" className="parallax-img-home">
          <div className="container">
            <div className="row home-padding-top-set d-flex justify-content-center">
              <div className="col-sm-10">
                <p className="header-sub-text-style-home font-size-set-42 font-fm">
                  OFERTA 3X1, 10 DIAS DE VACACIONES
                </p>
              </div>
            </div>
            <div className="row s-1-div">
              <div className="col-sm-4 col-md-12 col-lg-4 img-s-1">
                <img
                  className="side-img-1"
                  src="images/side-images/miami_orlando.jpg"
                  alt="side-1"
                ></img>
              </div>
              <div className="col-sm-8 col-md-12 col-lg-8 right-s-1-d">
                <div className="row pt-3">
                  <div className="col-sm-12">
                    <AiFillStar color="orange" />
                    <AiFillStar color="orange" />
                    <AiFillStar color="orange" />
                    <AiFillStar color="orange" />
                    <AiFillStar color="orange" />
                    <p className="font-fm gray">
                      5432 clientes les gusta este paquete
                    </p>
                    <h4 className="font-fm h4-s-1">
                      MIAMI, ORLANDO & CRUCERO A LAS BAHAMAS
                    </h4>
                    <p className="font-fm gray">
                      Aprovecha esta oferta especial de 10 Días | 9 Noches de
                      Alojamiento en Miami, Orlando y Crucero a Bahamas con
                      bebidas y comidas abordo del crucero. Oferta por tiempo
                      limitado!
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-center m-1 mb-3">
                  <div className="col-sm-12 col-md-8 col-lg-7 col-xl-7 home-search-box-style">
                    <p
                      id="no-destination-btn-home"
                      onClick={() => this.selectedDestination()}
                      className="btn-not-destination-select btn-not-destination-select-active font-fm"
                    >
                      Empecemos a Reservar
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <img src="images/side-images/wdw.png" alt="s"></img>
                    <img
                      className=""
                      src="images/side-images/un-s.png"
                      alt="s"
                    ></img>
                  </div>
                  <div className="col price-s-1-d">
                    <span className="font-fm old-price-s gray">Antes </span>
                    <p className="font-fm old-price-v gray"> $1079</p>
                    <p className="font-fm price-value-new">596 USD</p>
                    <p className="font-fm gray kids-p-s">
                      por adulto, $ 396 por niño
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-us-img-back">
          <div
            id="about-us-div"
            className="container about-us-details-home pb-5"
          >
            <div className="row pt-5 d-flex justify-content-center">
              <div className="col-sm-10">
                <p className="about-us-sub-home-text font-size-set-40 font-fm">
                  PAQUETE MIAMI, ORLANDO & CRUCERO A BAHAMAS
                </p>
              </div>
            </div>
            <div className="row d-flex justify-content-center  mb-5">
              <div className="col-sm-8">
                <p className="steps-p-sub font-size-set-14 text-align-set font-fm">
                  Porque no encontrarás un precio más bajo! Tenemos un gran
                  número de habitaciones disponibles, Podrás comprarlas hoy, y
                  utilizarlas en los próximos 12 meses. La oferta es limitada
                  y/o hasta agotar existencias con sorpresas increíbles a los
                  parques en Orlando.
                </p>
              </div>
            </div>
            <AboutCardSection />
            <div className="row d-flex justify-content-center">
              <div className="col-sm-3 mb-5 mt-5">
                <p
                  onClick={() => this.selectedDestination()}
                  className="btn-about-us-select-top font-fm"
                >
                  Reservar Ahora
                </p>
              </div>
            </div>
            <ImgDivCard
              selectedDestination={() => this.selectedDestination()}
            />
            <ImgDivCardTwo
              selectedDestination={() => this.selectedDestination()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
