import React from "react";

import "./PartnersSection.css";

function PartnersSection() {
  return (
    <div className="partners-section">
      {/* <hr /> */}
      <div className="container mt-4 mb-5">
        <h3 className="font-fm">
          <b>Algunos de Nuestros Amigos</b>
        </h3>
        <div className="logo-grid">
          <div>
            <img src="/images/companies-logo/united.svg" alt="" />
          </div>
          <div>
            <img src="/images/companies-logo/latam.svg" alt="" />
          </div>
          <div>
            <img src="/images/companies-logo/insuranceA4.svg" alt="" />
          </div>
          <div>
            <img src="/images/companies-logo/spirit.svg" alt="" />
          </div>
          <div>
            <img src="/images/companies-logo/avianca.svg" alt="" />
          </div>
          <div>
            <img src="/images/companies-logo/southwest.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="container associations">
        <div className="row">
          <div className="col-sm-6 mb-5">
            <h4 className="font-fm">En la prensa:</h4>
            <img src="/images/companies-logo/press.svg" alt="" />
          </div>
          <div className="col-sm-6 mb-5">
            <h4 className="font-fm">Asociaciones:</h4>
            <img src="/images/companies-logo/associations.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnersSection;
