import React, { Component } from "react";
import "./LoadingScreen.css";

class LoadingScreen extends Component {
  render() {
    return (
      <div className="loading-screen" id={"loading-screen-identifier"}>
        <div className={"video"}>
          <img className="logo-loading" src="/images/logo.jpg" alt="logo"></img>
        </div>
        <div className="loading-dots">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>
    );
  }
}

export default LoadingScreen;
