import React from "react";
import LazyLoad from "react-lazyload";

import "./ImgDivCard.css";

function ImgDivCard({ selectedDestination }) {
  return (
    <>
      <LazyLoad height={200} once>
        <div className="row card-white-div mb-5">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-7 col-md-12 col-lg-7">
                <div className="row mb-4">
                  <div className="col-sm-12 box-title-div">
                    <h4 className="img-div-h4 font-fm">
                      <b>ESTO ES LO QUE INCLUYE EL PAQUETE</b>
                    </h4>
                    <p className="img-div-p-h font-fm gray">
                      <b>Alojamiento durante 10 días y 9 noches</b> en Orlando,
                      Miami y crucero a las Bahamas.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <p className="img-div-p-h font-fm gray">
                      Comienza tus vacaciones con <b>3 días | 2 Noches</b> de
                      alojamiento en la fascinante ciudad del sol, en uno de
                      nuestros prestigiosos hoteles/Resorts ubicados en Miami o
                      Miami Beach{" "}
                      <a href="#id" className="img-div-a-tag">
                        (ver hoteles de la oferta)
                      </a>
                      . Disfrute de las playas, la increíble vida nocturna, lo
                      espectacular de ART DECO en Ocean Drive y la cultura
                      artística en galerías y restaurantes caminando por las
                      calles de Wynwood. Continua con alojamiento de{" "}
                      <b>6 Días | 5 Noches</b> en uno de nuestros
                      hoteles/resorts a escoger{" "}
                      <a href="#id" className="img-div-a-tag">
                        (ver hoteles de la oferta)
                      </a>
                      . Aproveche su estadía en Orlando y visite otros
                      maravillosos parques temáticos, como: Universal Studios®,
                      Islands of Adventures®, Typhoon Lagoon, Volcano Bay®,
                      Epcot Center® y Disney Animal Kingdom®. No olvide visitar
                      los mejores centros comerciales con tiendas de descuento
                      hasta un 60%, conocidos como Outlets, con todas las marcas
                      de los diseñadores más famosas. Finaliza tus vacaciones
                      con <b>3 días | 2 Noches</b> de abordo de un crucero con
                      comidas y bebidas no alcohólicas navegando a las Bahamas.
                      En el crucero podrás disfrutas de todo el entretenimiento
                      a bordo como spa, casinos, restaurantes, shows en vivo y
                      mucho más!{" "}
                      <a href="#id" className="img-div-a-tag">
                        (ver Crucero)
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-5 col-md-12 mt-3 col-lg-5">
                <img
                  className="img-card-div-img"
                  src="images/side-images/4.jpg"
                  alt="side-1"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} once>
        <div className="row card-white-div mb-5">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-5 col-md-12 col-lg-5">
                <img
                  className="img-card-div-img"
                  src="images/side-images/5.jpg"
                  alt="side-1"
                ></img>
              </div>
              <div className="col-sm-7 col-md-12 col-lg-7">
                <div className="row mb-4">
                  <div className="col-sm-12 box-title-div mt-3">
                    <h4 className="img-div-h4 font-fm">
                      <b>HABITACIONES SUPERIORES</b>
                    </h4>
                    <p className="img-div-p-h font-fm gray">
                      Estas habitaciones son ideales para familias y grupos que
                      necesitan un poco más de espacio.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <ul>
                      <li className="font-fm gray">
                        Vistas a la playa/piscina
                      </li>
                      <li className="font-fm gray">
                        Habitaciones contiguas disponibles
                      </li>
                      <li className="font-fm gray">
                        Una cama King-size o dos camas Queen-size
                      </li>
                      <li className="font-fm gray">
                        Habitaciones accesibles con una cama Queen-size
                      </li>
                      <li className="font-fm gray">
                        Ocupación máxima: 4 personas (incluyendo niños)
                      </li>
                      <li className="font-fm gray">
                        Terraza o balcón- Wifi gratuito
                      </li>
                      <li className="font-fm gray">
                        No fumadores- Aire acondicionado
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <ul>
                      <li className="font-fm gray">
                        Pantalla plana con televisión por cable- Teléfono
                      </li>
                      <li className="font-fm gray">
                        Baño con ducha (bañera bajo petición)
                      </li>
                      <li className="font-fm gray">
                        Artículos de aseo- Secador de pelo
                      </li>
                      <li className="font-fm gray">
                        Plancha y tabla de planchar
                      </li>
                      <li className="font-fm gray">
                        Mini-nevera- Cunas disponibles (bajo petición)
                      </li>
                      <li className="font-fm gray">Caja de seguridad ($)</li>
                      <li className="font-fm gray">
                        Voltaje: 110 voltios, 60 HZ
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-sm-12 font-center">
                    <p className="img-div-p-h font-fm gray">
                      Precio Final, incluye impuestos. Precio por persona y/o
                      menor de 12 años. <b>No</b> incluye tickets aereos
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} once>
        <div className="row card-white-div mb-5">
          <div className="col-sm-12 pt-5 pb-5">
            <div className="row">
              <div className="col-sm-6 col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-sm-4">
                    <img
                      className="img-card-div-img-c"
                      src="images/side-images/6.jpg"
                      alt="side-1"
                    ></img>
                  </div>
                  <div className="col-sm-8">
                    <h4 className="font-fm">
                      <b>Qué hay de nuevo en</b>
                    </h4>
                    <h4 className="font-fm wdw-c">Walt Disney World ®?</h4>
                    <p className="font-fm gray">
                      Mira lo que nuestros expertos en vacaciones te recomiendan
                      hoy en el siguiente video!
                    </p>
                    <div className="font-fm gray">
                      <img
                        className="img-1-c laptop-version"
                        src="images/side-images/1.png"
                        alt="side-1"
                      ></img>
                      #1 En asesoramiento a
                      <img
                        className="img-2-c"
                        src="images/side-images/2.png"
                        alt="side-1"
                      ></img>
                    </div>
                    <div className="font-fm gray">
                      <img
                        className="img-1-c laptop-version"
                        src="images/side-images/8.jpg"
                        alt="side-1"
                      ></img>
                      100% Clientes Felices!
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-12 col-lg-6 d-flex justify-content-center">
                <iframe
                  className="s-1-v-video"
                  src="https://www.youtube.com/embed/F-kRxU7SPk8"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} once>
        <div className="row card-white-div mb-5">
          <div className="col-sm-12 pt-5 pb-5">
            <div className="row">
              <div className="col-sm-6 col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-sm-4">
                    <img
                      className="img-card-div-img-c"
                      src="images/side-images/7.jpg"
                      alt="side-1"
                    ></img>
                  </div>
                  <div className="col-sm-8">
                    <h4 className="font-fm">
                      <b>Qué hay de nuevo en</b>
                    </h4>
                    <h4 className="font-fm wdw-c-1">MIAMI ?</h4>
                    <p className="font-fm gray">
                      Mira lo que nuestros expertos en vacaciones te recomiendan
                      hoy en el siguiente video!
                    </p>
                    <div className="font-fm gray">
                      <img
                        className="img-1-c laptop-version"
                        src="images/side-images/1.png"
                        alt="side-1"
                      ></img>
                      #1 viajando familias alrededor del mundo
                    </div>
                    <div className="font-fm gray">
                      <img
                        className="img-1-c laptop-version"
                        src="images/side-images/8.jpg"
                        alt="side-1"
                      ></img>
                      100% Satisfacción Garantizada
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-12 col-lg-6 d-flex justify-content-center">
                <iframe
                  className="s-1-v-video"
                  src="https://www.youtube.com/embed/yQCBAaJg1LE"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} once>
        <div className="row card-white-div mb-5">
          <div className="col-sm-12 pt-2 pb-2">
            <div className="row">
              <div className="col-sm-5 col-md-12 col-lg-5">
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-center">
                    <iframe
                      className="s-1-v-video"
                      src="https://www.youtube.com/embed/DOGOoyaHtSM"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
                <div className="row mt-3 d-flex justify-content-center">
                  <div className="col-sm-12 d-flex justify-content-center">
                    <p className="font-fm gray font-center">
                      Compra en linea y recibe <b>ticket a los parques!</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-7 col-md-12 col-lg-7">
                <div className="row">
                  <div className="col-sm-12 mb-4 font-center">
                    <img
                      className="img-card-div-img-d"
                      src="images/side-images/un-s.png"
                      alt="side-1"
                    ></img>
                    <img
                      className="img-card-div-img-d"
                      src="images/side-images/un-s-1.png"
                      alt="side-1"
                    ></img>
                  </div>
                </div>
                <div className="row  d-flex justify-content-center font-center">
                  <div className="col-sm-8">
                    <p className="font-fm gray">
                      Es como ver tus programas de TV y películas favoritas en
                      vida real, pero con las vueltas, giros y caídas de las
                      mejores atracciones. Enfréntate a villanos más tenebrosos
                      que tus niños, antes que se coman un churro. Salva el
                      planeta cada día o a cada hora Incluso hay dragones, no te
                      olvides de los dragones. Y uno de ellos suelta fuego de
                      verdad. Esto es más allá de las películas.
                    </p>
                    <p className="font-fm gray">Reserva hoy!</p>
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-3">
                    <p
                      onClick={() => selectedDestination()}
                      className="btn-about-us-select-top font-fm"
                    >
                      Reservar Ahora
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} once>
        <div className="row card-white-div mb-5">
          <div className="col-sm-12 pt-2 pb-2">
            <div className="row">
              <div className="col-sm-7 col-md-12 col-lg-7">
                <div className="row">
                  <div className="col-sm-12 mb-4 font-center">
                    <img
                      className="img-card-div-img-d"
                      src="images/side-images/2.png"
                      alt="side-1"
                    ></img>
                    <img
                      className="img-card-div-img-d"
                      src="images/side-images/op.png"
                      alt="side-1"
                    ></img>
                  </div>
                </div>
                <div className="row  d-flex justify-content-center font-center">
                  <div className="col-sm-10">
                    <p className="font-fm gray">
                      El Walt Disney World Resort, también conocido como Disney
                      World, es un complejo turístico famoso por sus parques
                      temáticos y numerosos hoteles. El complejo es operado por
                      Walt Disney Parks y cuenta con parques temáticos famosos
                      como Epcot, Disney's Hollywood Studios y Disney's Animal
                      Kingdom. Además de los cuatro parques temáticos
                      principales, el complejo cuenta con dos parques acuáticos,
                      seis circuitos de golf, un complejo deportivo, dos centros
                      comerciales, veinticuatro hoteles de Disney, numerosas
                      tiendas, y restaurantes.
                    </p>
                    <p className="font-fm gray">
                      No esperes más, y reserva hoy!
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-3">
                    <p
                      onClick={() => selectedDestination()}
                      className="btn-about-us-select-top font-fm"
                    >
                      Reservar Ahora
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="col-sm-5 col-md-12 col-lg-5">
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-center">
                    <iframe
                      className="s-1-v-video"
                      src="https://www.youtube.com/embed/mEyUzQUH-IY"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
                <div className="row mt-3 d-flex justify-content-center">
                  <div className="col-sm-12 d-flex justify-content-center">
                    <p className="font-fm gray">
                      Llamanos ahora y recibe <b>ticket a los parques!</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
    </>
  );
}

export default ImgDivCard;
