import React, { Component } from "react";

import { AiFillStar } from "react-icons/ai";

import "./AboutCardSection.css";
import "./AboutNewCss.css";

class AboutCardSection extends Component {
  state = {};
  render() {
    return [
      <div>
        <div className="row">
          <div className="col-sm-12 d-flex justify-content-center mb-5">
            <iframe
              className="about-us-video"
              src="https://www.youtube.com/embed/0h_Dnh0P7Bs"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="row s-1-div">
          <div className="col-sm-4 col-md-12 col-lg-4 img-s-1">
            <img
              className="side-img-1"
              src="images/side-images/1.jpg"
              alt="side-1"
            ></img>
          </div>
          <div className="col-sm-8 col-md-12 col-lg-8 right-s-1-d">
            <div className="row pt-3">
              <div className="col-sm-12">
                <AiFillStar color="blue" />
                <AiFillStar color="blue" />
                <AiFillStar color="blue" />
                <AiFillStar color="blue" />
                <AiFillStar />
                <p className="font-fm gray">
                  5432 clientes les gusta este paquete
                </p>
                <h4 className="font-fm h4-s-1">
                  10 DIAS | 9 NOCHES, DE VACACIONES, Vacation Coupon®{" "}
                  <b>INCLUYE:</b>
                </h4>
                <h5 className="font-fm"></h5>
                <ul>
                  <li className="font-fm gray">
                    10 días /9 noches de alojamiento en nuestros Hotel/Resorts
                    en Miami, Orlando y Crucero a Bahamas por persona
                  </li>
                  <li className="font-fm gray">
                    Ticket a los parques de Disney o universal
                  </li>
                  <li className="font-fm gray">
                    12 Meses para usar tus vacaciones*
                  </li>
                  <li className="font-fm gray">
                    Transporte a los parques en Orlando
                  </li>
                  <li className="font-fm gray">
                    Comidas y bebidas en los cruceros
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <img src="/images/side-images/wdw.png" alt="s"></img>
                <img
                  className=""
                  src="/images/side-images/un-s.png"
                  alt="s"
                ></img>
              </div>
              <div className="col price-s-1-d">
                <span className="font-fm old-price-s gray">Antes </span>
                <p className="font-fm old-price-v gray"> $1079</p>
                <p className="font-fm price-value-new">596 USD</p>
                <p className="font-fm gray kids-p-s">
                  por adulto, $ 396 por niño
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row  s-1-div">
          <div className="col-sm-12 pt-4 font-center">
            <h4 className="font-fm h4-s-2">PARQUES EN ORLANDO FL</h4>
            <p className="font-fm gray">
              Recibe dos dias de parques, y puedes escoger entre los siguientes
            </p>
          </div>
        </div>
        <div className="row  s-1-div">
          <div className="col-sm-12 pb-4 font-center">
            <img
              className="theme-cark-card-img"
              src="images/side-images/2.jpg"
              alt="s"
            ></img>
          </div>
        </div>
      </div>,
    ];
  }
}

export default AboutCardSection;
